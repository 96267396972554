import React from "react";
import address from "../../assets/images/address.svg";
import call from "../../assets/images/call.svg";
import "../info-nav/info-nav.scss";
import { Link } from "react-router-dom";

const InfoNav = () => {
  return (
    <div className="info-nav">
      <Link to="https://goo.gl/maps/j2XLGVjLNUwPShop7" target="_blank" className="address">
        <img src={address} alt="address-icon" />
        <p>2953 N Cobb Pkwy NW Suite 1 Kennesaw, GA 30152</p>
      </Link>
      <Link onclick="return gtag_report_conversion('tel:+16783880866');" to="tel:+16783880866" className="call">
        <img src={call} alt="call-icon" />
        <p>678 - 388 - 0866</p>
      </Link>
    </div>
  );
};

export default InfoNav;
