import React, { useEffect, useState } from "react";
import "./blog-detail-page.scss";
import male from "../../assets/images/t-male.png";
import { blogs } from "../../assets/data/blogs";
import { useParams } from "react-router-dom";
import CommonSection from "../../components/common-section/CommonSection";
import Helmet from "../../components/helmet/Helmet";

const BlogDetailPage = () => {
  const { id } = useParams();
  const [data, setData] = useState(blogs.find((item) => item.id === id));

  useEffect(() => {
    let newData = blogs.find((item) => item.id === id);
    setData(newData);
  }, []);

  const { img, title, postDate, name,paraDesc, paraHeading1,paraHeading2,paraHeading3,paraHeading4,paraHeading5,paraHeading6,paraHeading7,paraHeading8,paraHeading9,paraHeading10,paraHeading11,paraHeading12,paraHeading13,paraHeading14, para1, para2, para3 , para4, para5, para6, para7,para8,para9,para10,para11,para12,para13,para14,para15,para16} = data;

  return (
    <>
      <Helmet title={id} canonical={"https://www.massagenow-atl.com/blog/benefits-of-massage"} />
      <CommonSection title={id.replace(/-/g, " ")} />

      <div className="blog-detail-page">
        <img className="main-img" src={img} alt="thumbnail" />

        <h4 className="title">{title}</h4>

        <div className="info">
          <div className="left">
            <div className="name">
              <img src={male} alt="person" />
              <p>{name}</p>
            </div>
            <p className="date">
              Date . <span>{postDate}</span>
            </p>
          </div>

          <div className="right">
            <p>Location</p>
            <p>2953 N Cobb Pkwy NW Suite 1 Kennesaw, GA 30152</p>
          </div>
        </div>

        <div className="content">
          {paraDesc && <p className="para1">{paraDesc}</p>}

          {paraHeading1 && <h3>{paraHeading1}</h3>}
          {para1 && <p className="para1">{para1}</p>}

          {paraHeading2 && <h3>{paraHeading2}</h3>}
          {para2 && <p className="para2">{para2}</p>}

          {paraHeading3 && <h3>{paraHeading3}</h3>}
          {para3 && <p className="para3">{para3}</p>}

          {paraHeading4 && <h3>{paraHeading4}</h3>}
          {para4 && <p className="para3">{para4}</p>}

          {paraHeading5 && <h3>{paraHeading5}</h3>}
          {para5 && <p className="para3">{para5}</p>}

          {paraHeading6 && <h3>{paraHeading6}</h3>}
          {para6 && <p className="para3">{para6}</p>}


          {paraHeading7 && <h3>{paraHeading7}</h3>}
          {para7 && <p className="para2">{para7}</p>}
          {para8 && <p className="para2">{para8}</p>}


          {paraHeading8 && <h3>{paraHeading8}</h3>}
          {para9 && <p className="para3">{para9}</p>}
          {para10 && <p className="para2">{para11}</p>}

          {paraHeading9 && <h3>{paraHeading9}</h3>}
          {para11 && <p className="para2">{para11}</p>}
          {para12 && <p className="para2">{para12}</p>}

          {paraHeading10 && <h3>{paraHeading10}</h3>}
          {paraHeading11 && <h3>{paraHeading11}</h3>}
          {para13 && <p className="para2">{para13}</p>}

          {paraHeading12 && <h3>{paraHeading12}</h3>}
          {para14 && <p className="para2">{para14}</p>}

          {paraHeading13 && <h3>{paraHeading13}</h3>}
          {para15 && <p className="para2">{para15}</p>}


          {paraHeading14 && <h3>{paraHeading14}</h3>}
          {para16 && <p className="para2">{para16}</p>}


        </div>
      </div>
    </>
  );
};

export default BlogDetailPage;