import React from "react";
import "./gift-certificates.scss";
import CommonSection from "../../components/common-section/CommonSection";
import Helmet from "../../components/helmet/Helmet";

const GiftCertificates = () => {
  const locale = "en-US";
  const iframeSrc = `https://my.loopz.io/gift-card/05aaae3d-a980-4b74-a9ef-164df066112e?locale=${locale}`;

  return (
    <>
      <Helmet
        title={"Gift Massage Certificates To Your Loved Ones"}
        desc={
          "Share the joy with your loved ones by gifting customizable gift certificate for custom massages. Buy a gift certificate now!"
        }
        canonical={"https://www.massagenow-atl.com/giftcertificates"}
      />

      <CommonSection
        title={"E-Gift Certificates For Massage Now! In Kennesaw"}
      />
      <div className="gift-certificates">
        <h3 className="heading">PURCHASE E-GIFT CERTIFICATES</h3>
        <p className="desc">
          Unlock smiles, share the joy: Give the perfect gift with our
          customizable gift certificates!
        </p>
        
        {/* <iframe
          width="100%"
          title="gift_card"
          height="900"
          loading="lazy"
          id="loopz-card-purchase"
          src={iframeSrc}
          style={{
            borderWidth: 0,
            minWidth: "300px",
            margin: "0 0 0px 0",
            padding: "50px 0",
          }}
        ></iframe> */}
      </div>
    </>
  );
};

export default GiftCertificates;
