import React, { Suspense, useEffect, useState } from "react";
import InfoNav from "../info-nav/InfoNav";
import Header from "../header/Header";
import Routers from "../../routers/Routers";
import { useLocation } from "react-router-dom";
import BestAwardPopup from "../best-award-popup/BestAwardPopup";
const Footer = React.lazy(() => import("../footer/Footer"));

const Layout = () => {
  const[popup, setPopup] = useState(false)
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setPopup(true);
    }, 4000);

    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <div>
      {popup && <BestAwardPopup closePopup={setPopup} />}

      <InfoNav />
      <Header />
      <Routers />
      <Suspense fallback={<div>Loading...</div>}>
        <Footer />
      </Suspense>
    </div>
  );
};

export default Layout;
